import React, { createContext, useState } from 'react';

export const AzhaiAuthContext = createContext();

export const AzhaiAuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  console.log("auth",auth)
  return (
    <AzhaiAuthContext.Provider value={{ auth, setAuth }}>{children}</AzhaiAuthContext.Provider>
  );
};
